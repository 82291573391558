import { Confirm } from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { downloadFile } from "../../../apis/downloadApi";
import { useAdditionalStudyDelete, useEditSpecialInstructions, useGetAdditionalList } from "../../../apis/studyApi";
import * as S3 from "../../../elements/Button/style/ButtonLayout.style";
import { DropImageBox, DropImageBoxPrint } from "../../../elements/Drop/DropImageBox";
import * as S2 from "../../../elements/Form/style/Form.style";
import TeethSummaryBox from "../../../elements/TeethCheckBox/TeethSummaryBox";
import * as S4 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { additionalFormConfig, plasterModelSend, studyExtroImages, studyIntroImages, studyRadiographs } from "../../../utils/studyStatus";
import { CardTail } from "../../layout/CardLayout/CardLayout";
import * as S from "../style/Study.style";
import { SummaryTableLi } from "./StudyViewSummary";

const StudyViewAdditionalSummary = ({ patientId, studyId, additionalId, handlePrint, studyAuth }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  /**추가교정장치 처방전 정보 */
  const { data, isLoading, isError } = useGetAdditionalList(additionalId, {
    studyId,
    patientId,
  });
  const additionalInfo = data?.data;

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (src) => {
    setImageViewer({ imgSrc: [src], isView: true });
  };

  const additionalStudyDeleteMutation = useAdditionalStudyDelete();
  /**처방전 삭제 */
  const deleteAdditionalStudy = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        additionalStudyDeleteMutation.mutate(additionalId);
      },
      () => {}
    );
  };

  /**특별지시사항 수정 */
  const [isEditSpecialInstruction, setIsEditSpecialInstruction] = useState(false);
  const [specialInstruction, setSpecialInstruction] = useState(additionalInfo?.AI06);

  useEffect(() => {
    if (!isLoading && additionalInfo) {
      setSpecialInstruction(additionalInfo?.AI06);
    }
  }, [isLoading, additionalInfo]);

  const specialInstructionMutation = useEditSpecialInstructions("AdditionalSummary");

  /**특별지시사항 등록 */
  const editSpecialInstruction = () => {
    specialInstructionMutation.mutate(
      {
        type: "additional",
        id: additionalId,
        data: { memo: specialInstruction },
      },
      {
        onSuccess: () => {
          setIsEditSpecialInstruction(false);
        },
      }
    );
  };

  return (
    <>
      {isLoading || isError ? (
        <>
          <Skeleton height={300} />
          <br />
          <Skeleton height={800} />
        </>
      ) : (
        <>
          <S.StudySummary>
            {/* 의사명 */}
            <SummaryTableLi title={t(IK.doctor_name)}>
              {additionalInfo.doctor && (
                <p>
                  {additionalInfo.doctor.name} {additionalInfo.doctor?.koreaName && `(${additionalInfo.doctor.koreaName})`}
                </p>
              )}
            </SummaryTableLi>

            {/* 인쇄시 환자명, 치료옵션 보이도록 */}
            <SummaryTableLi printshow title={t(IK.patient_name)}>
              {additionalInfo?.patient && (
                <p>{`${additionalInfo.patient?.firstName} ${additionalInfo.patient?.lastName}${additionalInfo.patient?.korName ? ` (${additionalInfo.patient?.korName})` : ""}`}</p>
              )}
            </SummaryTableLi>
            <SummaryTableLi printshow title={t(IK.treatment_option)}>
              {additionalInfo.study && <p>{`${t(IK[additionalInfo.study?.packages])}`}</p>}
            </SummaryTableLi>

            {/* 배송지 */}
            <SummaryTableLi title={t(IK.shipping_address)}>{additionalInfo.patient && <p>{additionalInfo.patient.shipAddressName}</p>}</SummaryTableLi>

            {/* 청구지 */}
            <SummaryTableLi title={t(IK.billing_address)}>{additionalInfo.patient && <p>{additionalInfo.patient.billAddressName}</p>}</SummaryTableLi>

            {/* 의사 연락처 */}
            <SummaryTableLi title={t(IK.doctor_tel)}>{additionalInfo?.doctor && <p>{additionalInfo?.doctor?.phoneNumber}</p>}</SummaryTableLi>

            {/* 현재 환자가 착용하고 있는 장치 번호는? */}
            {(additionalInfo.AI02_01 || additionalInfo.AI02_02) && (
              <SummaryTableLi title={t(IK.current_device_number)}>
                <S2.FormControlsColumn>
                  {additionalInfo.AI02_01 && (
                    <p>
                      {t(IK.maxillary_aligner_number)} : {additionalInfo.AI02_01}
                    </p>
                  )}
                  {additionalInfo.AI02_02 && (
                    <p>
                      {t(IK.mandibular_aligner_number)} : {additionalInfo.AI02_02}
                    </p>
                  )}
                </S2.FormControlsColumn>
              </SummaryTableLi>
            )}

            {/* 어태치먼트 */}
            {additionalInfo.AI04 && (
              <SummaryTableLi title={t(IK.attachment_select)}>
                <p>{t(IK[`additional_attachment_item${additionalInfo.AI04}`])}</p>
              </SummaryTableLi>
            )}

            {/* 어태치먼트 AP*/}
            {additionalInfo.AI07 && (
              <SummaryTableLi title={t(IK.ap_attachment)} dataId="ApAttachment">
                {additionalInfo.AI07 === "1" && <p>{t(IK.ap_attachment_text_1)}</p>}
                {additionalInfo.AI07 === "2" && <p>{t(IK.ap_attachment_text_2)}</p>}
                {additionalInfo.AI07 === "3" && <p>{t(IK.ap_attachment_text_3)}</p>}
              </SummaryTableLi>
            )}

            {/* 이동에 제한이 있는 치아 */}
            {additionalInfo.AI05 && (
              <SummaryTableLi title={t(IK.teeth_limited_mobility)}>
                {additionalInfo.AI05 === "1" && <p>{t(IK.teeth_limited_mobility_item1)}</p>}
                {additionalInfo.AI05 === "2" && <TeethSummaryBox label="permanent" studyInfo={additionalInfo} config={additionalFormConfig.teeth_limited_mobility_item2_teeth} code="AI05" />}
              </SummaryTableLi>
            )}

            {/* 특별지시사항 */}
            <SummaryTableLi title={t(IK.special_instructions)} printhide>
              {isEditSpecialInstruction ? (
                <S2.FormControlsColumn>
                  <S2.FormControls as="textarea" $textarea $full defaultValue={additionalInfo.AI06} onChange={(e) => setSpecialInstruction(e.target.value)} />
                  <S3.ButtonFlex $small>
                    <S3.StyledSmallButton as="button" type="button" onClick={editSpecialInstruction}>
                      {t(IK.registration)}
                    </S3.StyledSmallButton>
                    <S3.StyledSmallButton $secondary as="button" type="button" onClick={() => setIsEditSpecialInstruction(false)}>
                      {t(IK.cancel)}
                    </S3.StyledSmallButton>
                  </S3.ButtonFlex>
                </S2.FormControlsColumn>
              ) : (
                <S2.FormControlsFlex>
                  {additionalInfo?.AI06 ? <S4.TextDiv $textpreline>{additionalInfo.AI06}</S4.TextDiv> : <></>}
                  {studyAuth.studySpecialFix && (
                    <S3.StyledSmallButton as="button" type="button" onClick={() => setIsEditSpecialInstruction(true)}>
                      {t(IK.modify)}
                    </S3.StyledSmallButton>
                  )}
                </S2.FormControlsFlex>
              )}
            </SummaryTableLi>

            {/* 구외 사진(프린트용) */}
            <DropImageBoxPrint text={t(IK.extraoral)} imagesArr={studyExtroImages} file={additionalInfo} printshow />

            {/* 구내 사진(프린트용) */}
            <DropImageBoxPrint text={t(IK.intraoral)} imagesArr={studyIntroImages} file={additionalInfo} printshow />

            {/* 방사선 사진(프린트용) */}
            <DropImageBoxPrint text={t(IK.radiograph)} imagesArr={studyRadiographs} file={additionalInfo} isRadiation printshow />
          </S.StudySummary>

          {/* 구외 사진 */}
          <DropImageBox text={t(IK.extraoral)} imagesArr={studyExtroImages} file={additionalInfo} handleImageViewer={handleImageViewer} />

          {/* 구내 사진 */}
          <DropImageBox text={t(IK.intraoral)} imagesArr={studyIntroImages} file={additionalInfo} handleImageViewer={handleImageViewer} />

          {/* 방사선 사진 */}
          <DropImageBox text={t(IK.radiograph)} imagesArr={studyRadiographs} file={additionalInfo} isRadiation handleImageViewer={handleImageViewer} />

          <S4.ContentBox>
            <S.StudySummary>
              {/* 스캔파일 등록 */}
              <SummaryTableLi title={t(IK.scan_file_registration)}>
                {additionalInfo?.scan?.length > 0 ? (
                  <S3.ButtonFlex $small>
                    {additionalInfo.scan.map((item, index) => {
                      return (
                        <S3.StyledSmallButton as="button" key={index} onClick={() => downloadFile(item.fileId, item.orgFileName, t)}>
                          {item.orgFileName}
                        </S3.StyledSmallButton>
                      );
                    })}
                  </S3.ButtonFlex>
                ) : (
                  <p>
                    {plasterModelSend(t, additionalInfo?.AIPlasterModel, additionalInfo?.AI40)} {additionalInfo?.AIPhoneNumber && `(${additionalInfo?.AIPhoneNumber})`}
                  </p>
                )}
              </SummaryTableLi>
            </S.StudySummary>
          </S4.ContentBox>

          <CardTail line>
            <S3.ButtonLtBox>
              <S3.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf || "?page=1"}`)}>
                {t(IK.list)}
              </S3.StyledButton>
              <S3.StyledButton as="button" type="button" $primary onClick={handlePrint}>
                {t(IK.print)}
              </S3.StyledButton>
              {studyAuth.studySuperAuth && (
                <S3.StyledButton as="button" type="button" $primary onClick={deleteAdditionalStudy}>
                  {t(IK.delete)}
                </S3.StyledButton>
              )}
            </S3.ButtonLtBox>
          </CardTail>
        </>
      )}
      {imageViewer.isView && (
        <S4.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S4.ImageViewerBox>
      )}
    </>
  );
};

export default StudyViewAdditionalSummary;
