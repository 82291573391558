import { useTranslation } from "react-i18next";
import profile from "../../assets/images/common/no_profile.png";
import * as S from "../../components/board/style/BoardList.style";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/SelectionControls/style/SelectionControls.style";
import { IK } from "../../utils/i18n/keyStore";
import CountryBadge from "../country/CountryBadge";

const ClinicalGalleryTr = ({ galleries, setMemoModal, isAllCountry, setChecks, checks }) => {
  const { t } = useTranslation(["translation"]);

  /**단건 체크 */
  const handleCheck = (id) => {
    setChecks((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <>
      {galleries.map((gallery) => (
        <tr key={gallery.clinicalId}>
          {isAllCountry && (
            <td>
              <S3.SelectionItem $labelhidden>
                <S3.SelectionItemLabel>
                  <S3.SelectionItemInput $checkbox type="checkbox" onChange={() => handleCheck(gallery.clinicalId)} checked={checks?.includes(gallery.clinicalId)} />
                </S3.SelectionItemLabel>
              </S3.SelectionItem>
            </td>
          )}
          <td>
            <S.BoardTableThumbnail>
              <img src={gallery.distFileName || profile} alt="thumbnail" />
            </S.BoardTableThumbnail>
          </td>
          <td>{gallery?.clinicalId}</td>
          <td>
            {gallery?.copiedFormId} {gallery?.copiedFormId && t(IK.copied)}
          </td>
          <td>{gallery?.submissionStatus === "REGISTERED" ? t(IK.clinical_submit) : t(IK.clinical_not_submit)}</td>
          <td>{gallery?.dentistName}</td>
          <td>{gallery?.patientName}</td>
          <td>{t(IK[gallery.packages])}</td>
          <td>{gallery?.createdDate}</td>
          {/**국가별 뱃지 */}
          {isAllCountry &&
            (gallery?.countryCodes ? (
              <td>
                <CountryBadge countryCodes={gallery?.countryCodes} />
              </td>
            ) : (
              <td></td>
            ))}
          <td>
            <S2.StyledSmallButton to={`/education/clinical-gallery/post/${gallery.clinicalId}`} state={{ herf: window.location.search }}>
              {t(IK.coupon_btn)}
            </S2.StyledSmallButton>
          </td>
          <td>
            {gallery.memo && (
              <S.BoardTableMemo type="button" onClick={() => setMemoModal({ isOpen: true, memo: gallery.memo })}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="5" y="5" width="5" height="5" rx="1" fill="currentColor"></rect>
                    <rect x="14" y="5" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                    <rect x="5" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                    <rect x="14" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                  </g>
                </svg>
              </S.BoardTableMemo>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default ClinicalGalleryTr;
