import dayjs from "dayjs";
import Notiflix from "notiflix";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../../elements/DatePicker";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";
import { onDateChange } from "../../utils/searchParams";
import CountryBtn from "../country/CountryBtn";
import SelectedCountryList from "../country/SelectedCountryList";

const EventFilter = ({ searchCondition, setSearchCondition, isCountryManager }) => {
  const { t } = useTranslation(["translation"]);

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      country_list: searchCondition?.country_list?.split(";") || [],
    },
  });

  const searchs = watch();

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    // 시작일과 종료일이 둘 중 하나만 선택되어 있을 경우 에러
    if ((data.searchStart && !data.searchEnd) || (!data.searchStart && data.searchEnd)) {
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }
    setSearchCondition((prev) => ({ ...prev, ...data, page: 1, country_list: data?.country_list?.join(";") || "" }));
  };

  /** 검색어 초기화*/
  const onReset = () => {
    //select 선택 초기화
    const selectElement = document.getElementById("dateControl");
    selectElement.selectedIndex = 0;

    //필드
    reset({
      searchSelect: "title",
      endEvent: "",
      searchText: "",
      searchStart: "",
      searchEnd: "",
      country_list: [],
    });
    setSearchCondition({ page: 1 });
  };

  /** 기간 검색*/
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    const [gap, gapDate] = dateValue.split(",");
    if (gap) {
      const startDate = new Date(dayjs().subtract(gap, gapDate).format("YYYY-MM-DD"));
      const endDate = new Date();
      setValue("searchStart", dayjs(startDate).format("YYYY-MM-DD"));
      setValue("searchEnd", dayjs(endDate).format("YYYY-MM-DD"));
    } else {
      setValue("searchStart", "");
      setValue("searchEnd", "");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <S2.TableType2>
        <colgroup>
          <col width="200px"></col>
          <col width="*"></col>
          <col width="200px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          {isCountryManager && (
            <tr>
              <th scope="row">{t(IK.select_country)}</th>
              <td>
                <CountryBtn setValue={setValue} fieldName="country_list" watch={searchs?.country_list} />
              </td>
              <th scope="row">{`${t(IK.country)} ${t(IK.list)}`}</th>
              <td>{searchs?.country_list?.length > 0 && <SelectedCountryList watch={searchs.country_list} fieldName="country_list" setValue={setValue} t={t} />}</td>
            </tr>
          )}
          <tr>
            <th scope="row">
              {t(IK.board_title)}/{t(IK.board_content)}
            </th>
            <td>
              <S3.FormControlsFlex $rowCenter>
                <S3.FormControls $select as="select" defaultValue={searchCondition?.searchSelect} {...register("searchSelect")}>
                  <option value="title">{t(IK.board_title)}</option>
                  <option value="content">{t(IK.board_content)}</option>
                </S3.FormControls>
                <S3.FormControls $regular type="text" placeholder={t(IK.search_text)} defaultValue={searchCondition?.searchText} {...register("searchText")} />
              </S3.FormControlsFlex>
            </td>
            <th scope="row">{t(IK.create_date)}</th>
            <td>
              <S3.FormControlsFlex $rowCenter>
                <S3.FormControls as="select" $select onChange={handleDateChange} id="dateControl">
                  <option value="">{t(IK.entire_date)}</option>
                  <option value={[1, "day"]}>{t(IK.day1)}</option>
                  <option value={[7, "day"]}>{t(IK.day7)}</option>
                  <option value={[1, "month"]}>{t(IK.month1)}</option>
                  <option value={[6, "month"]}>{t(IK.month6)}</option>
                  <option value={[12, "month"]}>{t(IK.year1)}</option>
                </S3.FormControls>

                <DatePicker
                  placeholderText={t(IK.date_example)}
                  selected={(searchs.searchStart && new Date(searchs.searchStart)) || (searchCondition?.searchStart && new Date(searchCondition?.searchStart))}
                  handleDataChange={(date) => onDateChange(date, setValue, "searchStart")}
                  formControls
                />

                <DatePicker
                  placeholderText={t(IK.date_example)}
                  selected={(searchs.searchEnd && new Date(searchs.searchEnd)) || (searchCondition?.searchEnd && new Date(searchCondition?.searchEnd))}
                  handleDataChange={(date) => onDateChange(date, setValue, "searchEnd")}
                  formControls
                />
              </S3.FormControlsFlex>
            </td>
          </tr>
          <tr>
            <th scope="row">{t(IK.event_is_progress_title)}</th>
            <td colSpan={3}>
              <S3.FormControls {...register("endEvent")} defaultValue={searchCondition?.endEvent} as="select" $select>
                <option value="">{t(IK.event_is_progress)}</option>
                <option value="N">{t(IK.event_is_progress_y)}</option>
                <option value="Y">{t(IK.event_is_progress_n)}</option>
              </S3.FormControls>
            </td>
          </tr>
        </tbody>
      </S2.TableType2>
      <S4.ButtonCtBox>
        <S4.StyledButton type="button" onClick={onReset} as="button">
          <i className="ri-refresh-line"></i> {t(IK.reset)}
        </S4.StyledButton>
        <S4.StyledButton $primary type="submit" as="button">
          <i className="ri-search-2-line"></i> {t(IK.search)}
        </S4.StyledButton>
      </S4.ButtonCtBox>
    </form>
  );
};

export default EventFilter;
