import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageButton } from "../../../elements/Language/Language";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/Footer.style";

const Footer = () => {
  const { t, i18n } = useTranslation(["translation"]);

  /**언어 설정을 변경한 유저의 값 가져오기 */
  const [langInfo, setLangInfo] = useState(localStorage.getItem("langInfo"));

  useEffect(() => {
    i18n.changeLanguage(langInfo);
  }, [langInfo, i18n]);

  return (
    <S.FooterBox>
      <S.Copyright>{t(IK.tns_copyright)}</S.Copyright>
      <S.FooterInfo>
        <li>
          {/* 언어선택 */}
          <LanguageButton langInfo={langInfo} setLangInfo={setLangInfo} />
        </li>
      </S.FooterInfo>
    </S.FooterBox>
  );
};

export default Footer;
