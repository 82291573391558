import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useAddGallery, useEditGallery, useGetGalleryinfo } from "../apis/boardApi";
import { userAuth } from "../atom/userAtom";
import CliniCalGalleryInfoSkeleton from "../components/clinicalGallery/CliniCalGalleryInfoSkeleton";
import ClinicalFile from "../components/clinicalGallery/ClinicalFile";
import ClinicalPreview from "../components/clinicalGallery/ClinicalPreview";
import ClinicalTail from "../components/clinicalGallery/ClinicalTail";
import CountryBtn from "../components/country/CountryBtn";
import SelectedCountryList from "../components/country/SelectedCountryList";
import { CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../elements/Form/FormLayout";
import * as S2 from "../elements/Form/style/Form.style";
import { ErrorMessage, ErrorsMessage } from "../elements/Message/MessageText";
import * as S4 from "../elements/SelectionControls/style/SelectionControls.style";
import {
  achievedList,
  clinicConditionCheckList,
  clinicalPackages,
  fDropImages,
  fRadiation,
  iDropImages,
  iRadiation,
  nonSerafinDeviceList,
  pDropImages,
  serafinDeviceList,
} from "../utils/clinicalUtils";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";

const CliniCalGalleryInfo = () => {
  const { t } = useTranslation(["translation"]);
  const { galleryId } = useParams();

  /**유저정보 조회 */
  const { countryCode } = useRecoilValue(userAuth);
  const isAllCountry = countryCode === "00";

  const { data, isLoading } = useGetGalleryinfo(galleryId);
  const galleryData = data?.data;

  /**유효성 검사 */
  const formSchema = Yup.object({
    countryCodes: Yup.array().required(t(IK.country_required)).min(1, t(IK.country_required)),
    gender: Yup.string().required(t(IK.gender_required)),
    age: Yup.string().required(t(IK.age_required)).max(3, t(IK.age_max)),
    race: Yup.string().required(t(IK.race_required)),
    packages: Yup.string().required(t(IK.treatment_option_required)),
    conditions: Yup.array().when("conditionsOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.check_one_over)),
    }),
    etc: Yup.string().when("conditionsOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    summaryUpperArch: Yup.string().required(t(IK.total_treatment_device_required)),
    summaryLowerArch: Yup.string().required(t(IK.total_treatment_device_required)),
    clinicalPlan: Yup.array()
      .of(Yup.string().required(t(IK.treatment_plan_required)))
      .min(1, t(IK.treatment_plan_required)),
    clinicalMonth: Yup.string().required(t(IK.total_treatment_time_required)),
    clinicalTime: Yup.string().required(t(IK.aligner_wear_time_required)),
    clinicalDay: Yup.string().required(t(IK.aligner_wear_day_required)),
    nonSerafinDevice: Yup.array().when("nonSerafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.gallery_other_device_required)),
    }),
    nonSerafinDeviceText: Yup.string().when("nonSerafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    serafinDevice: Yup.array().when("serafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.gallery_device_check_required)),
    }),
    serafinDeviceText: Yup.string().when("serafinDeviceOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    clinicalAchieved: Yup.array().when("clinicalAchievedOther", {
      is: (val) => val,
      then: (val) => Yup.array(),
      otherwise: (val) => Yup.array().min(1, t(IK.gallery_achieved_check_required)),
    }),
    clinicalAchievedText: Yup.string().when("clinicalAchievedOther", {
      is: (val) => val,
      then: (val) => Yup.string().required(t(IK.othertext_required)),
    }),
    i_occlusal_upper: Yup.mixed().required(t(IK.image_required)),
    i_occlusal_lower: Yup.mixed().required(t(IK.image_required)),
    i_buccal_right: Yup.mixed().required(t(IK.image_required)),
    i_overjet: Yup.mixed().required(t(IK.image_required)),
    i_buccal_left: Yup.mixed().required(t(IK.image_required)),
    f_occlusal_upper: Yup.mixed().required(t(IK.image_required)),
    f_occlusal_lower: Yup.mixed().required(t(IK.image_required)),
    f_buccal_right: Yup.mixed().required(t(IK.image_required)),
    f_overjet: Yup.mixed().required(t(IK.image_required)),
    f_buccal_left: Yup.mixed().required(t(IK.image_required)),
    i_panorama: Yup.mixed().required(t(IK.image_required)),
  });

  /**검색 조건 설정 */
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      conditions: [],
      clinicalPlan: [],
      serafinDevice: [],
      nonSerafinDevice: [],
      conditionsOther: false,
      serafinDeviceOther: false,
      nonSerafinDeviceOther: false,
      clinicalAchieved: [],
      clinicalAchievedOther: false,
    },
  });

  /**인풋 수 관리 */
  const [inputCount, setInputCount] = useState(1);

  useEffect(() => {
    // 데이터가 로딩된 후에 폼에 기본 값 넣기
    if (!isLoading && galleryData) {
      const { detail, countryCodes, ...rest } = galleryData;
      reset({
        ...rest,
        ...detail,
        clinicalPlan: detail?.clinicalPlan?.split(";"),
      });
      if (!!galleryData.etc) setValue("conditionsOther", true);
      if (!!detail.nonSerafinDeviceText) setValue("nonSerafinDeviceOther", true);
      if (!!detail.serafinDeviceText) setValue("serafinDeviceOther", true);
      if (!!detail.clinicalAchievedText) setValue("clinicalAchievedOther", true);
      setInputCount(detail?.clinicalPlan?.split(";").length || 1);
      if (countryCodes?.length > 0) setValue("countryCodes", countryCodes);
    }
  }, [isLoading, galleryData, reset, setValue]);

  /**인풋 값 추적 */
  const boardWatch = watch();

  /**인풋 증가 최대 5개 */
  const handleAddInput = () => {
    setInputCount((prevCount) => prevCount + 1);
  };

  /**인풋 감소 최소 1개*/
  const handleRemoveInput = (values, setValue) => {
    if (values.clinicalPlan.length === inputCount) {
      const newValues = values.clinicalPlan.slice(0, -1);
      setValue("clinicalPlan", newValues);
      reset({ ...values, clinicalPlan: newValues });
      trigger("clinicalPlan");
    }
    setInputCount((prevCount) => prevCount - 1);
  };

  /**삭제할 파일 아이디 */
  const [deleteClinicalFileIds, setDeleteClinicalFileIds] = useState([]);

  const addMutation = useAddGallery();
  const editMutation = useEditGallery();

  /**임상증례 제출 */
  const onSubmitClinic = (data) => {
    const formData = new FormData();
    const { clinicalPlan, ...rest } = data;
    const submitData = {
      deleteClinicalFileIds,
      ...rest,
      clinicalPlan: clinicalPlan.join(";"),
    };
    for (const [key, value] of Object.entries(submitData)) {
      if (!value || (Array.isArray(value) && value.length === 0) || value.clinicalFileId) {
        // 빈값이거나 이미 등록된 파일이면 건너뛴다.
        continue;
      }
      formData.append(key, value);
    }
    if (galleryId) {
      editMutation.mutate({ galleryId, formData });
    } else {
      addMutation.mutate(formData);
    }
  };

  /**수동 트리거 */
  const handleTrigger = (event, othersName, textLabel, triggers) => {
    const { checked } = event.target;
    setValue(othersName, checked);
    if (!checked) setValue(textLabel, "");
    trigger(triggers);
  };

  /**수동 텍스트 트리거 */
  const handleTextTrigger = (event, textLabel, triggers) => {
    setValue(textLabel, event.target.value);
    trigger(triggers);
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <CardTitle title={t(IK.clinical_case)} txt />
          {isLoading ? (
            <CliniCalGalleryInfoSkeleton />
          ) : (
            <form onSubmit={handleSubmit(onSubmitClinic)} onKeyDown={enterKeyDown}>
              <S2.FormControlsContainer>
                {galleryData?.createdDate && <FormControlsBox title={t(IK.register_date)}>{galleryData?.createdDate}</FormControlsBox>}

                <FormControlsBox title={t(IK.patient_name)}>
                  <S2.FormControls type="text" {...register("patientName")} disabled={!isAllCountry} />
                </FormControlsBox>

                {galleryData?.dentistName && <FormControlsBox title={t(IK.clinic_name)}>{galleryData?.dentistName}</FormControlsBox>}

                {/* 국가 선택 */}
                {isAllCountry && (
                  <>
                    <FormControlsBox required title={t(IK.country)}>
                      <CountryBtn setValue={setValue} clearErrors={clearErrors} watch={boardWatch?.countryCodes} />
                      <ErrorMessage errors={errors} name="countryCodes" />
                    </FormControlsBox>
                    {boardWatch?.countryCodes?.length > 0 && (
                      <FormControlsBox required title={`${t(IK.country)} ${t(IK.list)}`}>
                        <SelectedCountryList watch={boardWatch.countryCodes} setValue={setValue} t={t} />
                      </FormControlsBox>
                    )}
                  </>
                )}
                {/* 성별 */}
                <FormControlsBox required title={t(IK.gender)}>
                  <S4.SelectionItemList>
                    {["male", "female"].map((item) => (
                      <S4.SelectionItem key={item}>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput $radio type="radio" value={item} {...register("gender")} disabled={!isAllCountry} />
                          <S4.SelectionItemSpan>{t(IK[item])}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                    ))}
                  </S4.SelectionItemList>
                  <ErrorMessage errors={errors} name="gender" />
                </FormControlsBox>

                {/* 나이 */}
                <FormControlsBox required title={t(IK.age)}>
                  <S2.FormControls type="number" {...register("age")} $small disabled={!isAllCountry} />
                  <ErrorMessage errors={errors} name="age" />
                </FormControlsBox>

                {/* 인종 */}
                <FormControlsBox required title={t(IK.race)}>
                  <S4.SelectionItemList>
                    {["Caucasian", "Asian", "African"].map((item) => (
                      <S4.SelectionItem key={item}>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput $radio type="radio" value={item} {...register("race")} disabled={!isAllCountry} />
                          <S4.SelectionItemSpan>{t(IK[item?.toLocaleLowerCase()])}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                    ))}
                  </S4.SelectionItemList>
                  <ErrorMessage errors={errors} name="race" />
                </FormControlsBox>

                {/* 치료 옵션 */}
                <FormControlsBox required title={t(IK.treatment_option)}>
                  <S2.FormControls as="select" $select {...register("packages")} disabled={!isAllCountry}>
                    {Object.entries(clinicalPackages).map(([value, label]) => (
                      <option key={label} value={value}>
                        {label === "None" ? t(IK.select_treatment_options) : label}
                      </option>
                    ))}
                  </S2.FormControls>
                  <ErrorMessage errors={errors} name="packages" />
                </FormControlsBox>

                {/* 임상상태 */}
                <FormControlsBox required title={t(IK.clinical_condition)}>
                  <S2.FormControlsFlex>
                    {[0, 1, 2].map((item) => (
                      <S2.FormControlsDiv as="li" key={item}>
                        <S2.FormControlsColumn $large>
                          {clinicConditionCheckList[item].map((condition) => (
                            <S4.SelectionItem key={condition.label}>
                              <S4.SelectionItemLabel>
                                <S4.SelectionItemInput $checkbox type="checkbox" value={condition.value} {...register("conditions")} disabled={!isAllCountry} />
                                <S4.SelectionItemSpan>{t(condition.label)}</S4.SelectionItemSpan>
                              </S4.SelectionItemLabel>
                            </S4.SelectionItem>
                          ))}
                        </S2.FormControlsColumn>
                      </S2.FormControlsDiv>
                    ))}

                    <S2.FormControlsColumn as="li">
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput
                            $checkbox
                            type="checkbox"
                            {...register("conditionsOther")}
                            onChange={(e) => handleTrigger(e, "conditionsOther", "etc", ["conditions", "etc"])}
                            disabled={!isAllCountry}
                          />
                          <S4.SelectionItemSpan>{t(IK.other)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                      <S2.FormControls
                        as="textarea"
                        $textarea
                        {...register("etc")}
                        disabled={!boardWatch?.conditionsOther || !isAllCountry}
                        onChange={(e) => handleTextTrigger(e, "etc", ["conditions", "etc"])}
                      />
                    </S2.FormControlsColumn>
                  </S2.FormControlsFlex>
                  <ErrorMessage errors={errors} name="conditions" />
                  <ErrorMessage errors={errors} name="etc" />
                </FormControlsBox>

                {/* 메모 */}
                <FormControlsBox title={t(IK.memo)}>
                  <S2.FormControls as="textarea" $textarea {...register("memo")} disabled={!isAllCountry} />
                </FormControlsBox>

                {/* 치료 계획 */}
                <FormControlsBox required title={t(IK.treatment_plan)}>
                  <S2.FormControlsColumn>
                    {Array.from({ length: inputCount }, (_, index) => (
                      <S2.FormControlsFlex $rowCenter key={`clinicalPlan${index}`}>
                        <S2.FormControls {...register(`clinicalPlan[${index}]`)} disabled={!isAllCountry} />
                        {index + 1 === inputCount && (
                          <>
                            {index !== 4 && (
                              <S3.StlyedSquareButton type="button" $add onClick={handleAddInput} disabled={!isAllCountry}>
                                <i className="ri-add-line"></i>
                              </S3.StlyedSquareButton>
                            )}
                            {index !== 0 && (
                              <S3.StlyedSquareButton
                                type="button"
                                $delete
                                onClick={() => {
                                  handleRemoveInput(boardWatch, setValue);
                                }}
                                disabled={!isAllCountry}
                              >
                                <i className="ri-subtract-line"></i>
                              </S3.StlyedSquareButton>
                            )}
                          </>
                        )}
                      </S2.FormControlsFlex>
                    ))}
                  </S2.FormControlsColumn>
                  <ErrorsMessage errors={errors} name="clinicalPlan" />
                </FormControlsBox>

                {/* 치료 요약 */}
                <FormControlsBox required title={t(IK.treatment_summary)}>
                  <S2.FormControlsColumn $large>
                    {/* 총 치료 기간 */}
                    <FormControlsBox title={`1) ${t(IK.total_treatment_time)}`}>
                      <S2.FormControlsFlex>
                        <S2.FormLineControls type="text" {...register("clinicalMonth")} disabled={!isAllCountry} />
                        {t(IK.months)}
                      </S2.FormControlsFlex>
                      <ErrorMessage name="clinicalMonth" errors={errors} />
                    </FormControlsBox>

                    {/* 장치 수 */}
                    <FormControlsBox title={`2) ${t(IK.total_treatment_device)}`}>
                      <S2.FormControlsFlex>
                        <div>
                          <S2.FormControlsFlex>
                            {t(IK.maxilla)}
                            <S2.FormLineControls type="text" {...register("summaryUpperArch")} disabled={!isAllCountry} />
                          </S2.FormControlsFlex>
                          <ErrorMessage name="summaryUpperArch" errors={errors} />
                        </div>

                        <div>
                          <S2.FormControlsFlex>
                            {t(IK.mandible)}
                            <S2.FormLineControls type="text" {...register("summaryLowerArch")} disabled={!isAllCountry} />
                          </S2.FormControlsFlex>
                          <ErrorMessage name="summaryLowerArch" errors={errors} />
                        </div>
                      </S2.FormControlsFlex>
                    </FormControlsBox>

                    {/* 장치 착용 시간 */}
                    <FormControlsBox title={`3) ${t(IK.aligner_wear_time)}`}>
                      <S2.FormControlsFlex>
                        <div>
                          <S2.FormControlsFlex>
                            {t(IK.day)}
                            <S2.FormLineControls type="text" {...register("clinicalTime")} disabled={!isAllCountry} />
                            {t(IK.hour_by_hour)}
                          </S2.FormControlsFlex>
                          <ErrorMessage name="clinicalTime" errors={errors} />
                        </div>
                        <div>
                          <S2.FormControlsFlex>
                            {t(IK.per_device)}
                            <S2.FormLineControls type="text" {...register("clinicalDay")} disabled={!isAllCountry} />
                            {t(IK.day_use)}
                          </S2.FormControlsFlex>
                          <ErrorMessage name="clinicalDay" errors={errors} />
                        </div>
                      </S2.FormControlsFlex>
                    </FormControlsBox>

                    {/* 세라핀 외 장치 */}
                    <FormControlsBox title={`4) ${t(IK.gallery_other_device)}`}>
                      <S4.SelectionItemList>
                        {Object.entries(nonSerafinDeviceList).map(([key, label]) => (
                          <S4.SelectionItemLabel key={key}>
                            <S4.SelectionItemInput $checkbox type="checkbox" {...register("nonSerafinDevice")} value={key} disabled={!isAllCountry} />
                            <S4.SelectionItemSpan>{label}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        ))}
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput
                            $checkbox
                            type="checkbox"
                            {...register("nonSerafinDeviceOther")}
                            onChange={(e) => handleTrigger(e, "nonSerafinDeviceOther", "nonSerafinDeviceText", ["nonSerafinDevice", "nonSerafinDeviceText"])}
                            disabled={!isAllCountry}
                          />
                          <S4.SelectionItemSpan>{t(IK.other)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                        {boardWatch?.nonSerafinDeviceOther && (
                          <S2.FormLineControls
                            $large
                            type="text"
                            {...register("nonSerafinDeviceText")}
                            onChange={(e) => handleTextTrigger(e, "nonSerafinDeviceText", ["nonSerafinDevice", "nonSerafinDeviceText"])}
                            disabled={!isAllCountry}
                          />
                        )}
                      </S4.SelectionItemList>
                      <ErrorMessage name="nonSerafinDevice" errors={errors} />
                      <ErrorMessage name="nonSerafinDeviceText" errors={errors} />
                    </FormControlsBox>

                    {/* 유지장치 */}
                    <FormControlsBox title={`5) ${t(IK.gallery_devide_check)}`}>
                      <S4.SelectionItemList>
                        {Object.entries(serafinDeviceList).map(([key, label]) => (
                          <S4.SelectionItemLabel key={key}>
                            <S4.SelectionItemInput $checkbox type="checkbox" {...register("serafinDevice")} value={key} disabled={!isAllCountry} />
                            <S4.SelectionItemSpan>{label}</S4.SelectionItemSpan>
                          </S4.SelectionItemLabel>
                        ))}
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput
                            $checkbox
                            type="checkbox"
                            {...register("serafinDeviceOther")}
                            onChange={(e) => handleTrigger(e, "serafinDeviceOther", "serafinDeviceText", ["serafinDevice", "serafinDeviceText"])}
                            disabled={!isAllCountry}
                          />
                          <S4.SelectionItemSpan>{t(IK.other)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                        {boardWatch?.serafinDeviceOther && (
                          <S2.FormLineControls
                            $large
                            type="text"
                            {...register("serafinDeviceText")}
                            onChange={(e) => handleTextTrigger(e, "serafinDeviceText", ["serafinDevice", "serafinDeviceText"])}
                            disabled={!isAllCountry}
                          />
                        )}
                      </S4.SelectionItemList>
                      <ErrorMessage name="serafinDevice" errors={errors} />
                      <ErrorMessage name="serafinDeviceText" errors={errors} />
                    </FormControlsBox>
                  </S2.FormControlsColumn>
                </FormControlsBox>

                {/* Results achieved */}
                <FormControlsBox required title={"Results achieved"}>
                  <S4.SelectionItemList $gridColumns3fr>
                    {Object.entries(achievedList).map(([label, value]) => (
                      <S4.SelectionItemLabel key={label}>
                        <S4.SelectionItemInput $checkbox type="checkbox" {...register("clinicalAchieved")} value={value} disabled={!isAllCountry} />
                        <S4.SelectionItemSpan>{label}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    ))}
                    <S2.FormControlsFlex>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput
                          $checkbox
                          type="checkbox"
                          {...register("clinicalAchievedOther")}
                          onChange={(e) => handleTrigger(e, "clinicalAchievedOther", "clinicalAchievedText", ["clinicalAchieved", "clinicalAchievedText"])}
                          disabled={!isAllCountry}
                        />
                        <S4.SelectionItemSpan>{t(IK.other)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                      {boardWatch?.clinicalAchievedOther && (
                        <S2.FormLineControls
                          $large
                          type="text"
                          {...register("clinicalAchievedText")}
                          onChange={(e) => handleTextTrigger(e, "clinicalAchievedText", ["clinicalAchieved", "clinicalAchievedText"])}
                          disabled={!isAllCountry}
                        />
                      )}
                    </S2.FormControlsFlex>
                  </S4.SelectionItemList>
                  <ErrorMessage name="clinicalAchieved" errors={errors} />
                  <ErrorMessage name="clinicalAchievedText" errors={errors} />
                </FormControlsBox>

                {/* 구내 사진 */}
                <FormControlsBox required title={t(IK.intraoral)}>
                  <S2.FormControlsColumn>
                    <ClinicalPreview
                      required
                      galleryData={galleryData}
                      setValue={setValue}
                      imageList={iDropImages}
                      deleteClinicalFileIds={deleteClinicalFileIds}
                      setDeleteClinicalFileIds={setDeleteClinicalFileIds}
                      label={t(IK.treatment_before)}
                      errors={errors}
                      trigger={trigger}
                      disabled={!isAllCountry}
                    />
                    <ClinicalPreview
                      galleryData={galleryData}
                      setValue={setValue}
                      imageList={pDropImages}
                      deleteClinicalFileIds={deleteClinicalFileIds}
                      setDeleteClinicalFileIds={setDeleteClinicalFileIds}
                      label={t(IK.in_treatment_aligner_wear_photo)}
                      errors={errors}
                      disabled={!isAllCountry}
                    />
                    <ClinicalPreview
                      required
                      galleryData={galleryData}
                      setValue={setValue}
                      imageList={fDropImages}
                      deleteClinicalFileIds={deleteClinicalFileIds}
                      setDeleteClinicalFileIds={setDeleteClinicalFileIds}
                      label={t(IK.treatment_after_premises_photo)}
                      errors={errors}
                      trigger={trigger}
                      disabled={!isAllCountry}
                    />
                  </S2.FormControlsColumn>
                </FormControlsBox>

                {/* 방사선 사진 */}
                <FormControlsBox required title={t(IK.radiograph)}>
                  <S2.FormControlsColumn>
                    <ClinicalPreview
                      required
                      galleryData={galleryData}
                      setValue={setValue}
                      imageList={iRadiation}
                      deleteClinicalFileIds={deleteClinicalFileIds}
                      setDeleteClinicalFileIds={setDeleteClinicalFileIds}
                      label={t(IK.treatment_before)}
                      isRadiation
                      errors={errors}
                      disabled={!isAllCountry}
                    />
                    <ClinicalPreview
                      galleryData={galleryData}
                      setValue={setValue}
                      imageList={fRadiation}
                      deleteClinicalFileIds={deleteClinicalFileIds}
                      setDeleteClinicalFileIds={setDeleteClinicalFileIds}
                      label={t(IK.treatment_after_premises_photo)}
                      isRadiation
                      errors={errors}
                      disabled={!isAllCountry}
                    />
                  </S2.FormControlsColumn>
                </FormControlsBox>

                {/* 세라뷰 정면 썸네일 */}
                <ClinicalFile
                  title={t(IK.seraview_front_thumb)}
                  setValue={setValue}
                  name="front_video_thumb"
                  isImg
                  value={boardWatch.front_video_thumb?.name || galleryData?.front_video_thumb?.orgFileName || ""}
                  disabled={!isAllCountry}
                />

                {/* 세라뷰 정면 동영상(최대 1개) */}
                <ClinicalFile
                  title={t(IK.seraview_front_video)}
                  setValue={setValue}
                  name="front_video"
                  value={boardWatch.front_video?.name || galleryData?.front_video?.orgFileName || ""}
                  disabled={!isAllCountry}
                />

                {/* 세라뷰 복합 썸네일 */}
                <ClinicalFile
                  title={t(IK.seraview_multi_thumb)}
                  setValue={setValue}
                  name="multi_video_thumb"
                  isImg
                  value={boardWatch.multi_video_thumb?.name || galleryData?.multi_video_thumb?.orgFileName || ""}
                  disabled={!isAllCountry}
                />

                {/* 세라뷰 복합 동영상(최대 1개) */}
                <ClinicalFile
                  title={t(IK.seraview_multi_video)}
                  setValue={setValue}
                  name="multi_video"
                  value={boardWatch.multi_video?.name || galleryData?.multi_video?.orgFileName || ""}
                  disabled={!isAllCountry}
                />
              </S2.FormControlsContainer>
              <ClinicalTail submissionStatus={galleryData?.submissionStatus} isAllCountry={isAllCountry} />
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default CliniCalGalleryInfo;
