import Notiflix, { Confirm } from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDownloadBillExcel, useChangeStatus, useGetBillDetail, useReRequestPayment, useRequestPayment, useSaveBilling } from "../apis/billApi";
import BillViewSkeleton from "../components/billing/BillViewSkeleton";
import PaymentDetailModal from "../components/billing/PaymentDetailModal";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import * as S3 from "../elements/Form/style/Form.style";
import * as S5 from "../elements/Table/style/Table.style";
import useCalculateTotal from "../hooks/useCalculateTotal";
import * as S4 from "../styles/Common";
import { IK } from "../utils/i18n/keyStore";
import { billsStatus, formatCurrency } from "../utils/paymentUtils";
import { seraphinOption } from "../utils/studyStatus";

const BillView = () => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  const { billingId } = useParams();
  const { isLoading, data } = useGetBillDetail(billingId);
  const [billsInfo, setBillsInfo] = useState([]);
  const [isSave, setIsSave] = useState(false);

  /**처음 마운트시 데이터 담기 */
  useEffect(() => {
    if (!isLoading) {
      //결제완료와 전액할인은 제외
      const modifyData = data?.adminBillingDetailDTOList.map((item) => ({
        ...item,
        isModify: item.billingDetailStatus === 0 || item.billingDetailStatus === 10,
      }));
      setBillsInfo(modifyData);
    }
  }, [isLoading, data]);

  /**총금액 계산 */
  const [totals, setTotals] = useState({
    /**할인 금액 */
    discountPrice: 0,
    /**결제 금액 */
    paymentPrice: 0,
    /**총 금액 */
    salePrice: 0,
    /**잔여 금액 */
    remainPrice: 0,
  });

  /**총합 금액 계산하기 */
  useCalculateTotal(billsInfo, isLoading, setTotals);

  /**결제요청으로 상태 변경 */
  const reqeustMutation = useRequestPayment();

  /**결제예정으로 상태 되돌리기 */
  const reRequestMutation = useReRequestPayment();

  const saveMutation = useSaveBilling();
  /**결제 상태 저장 */
  const handleSave = () => {
    const updateData = billsInfo.filter((item) => item.billingDetailStatus === 0 || item.billingDetailStatus === 10 || item.billingDetailStatus === 90);
    if (updateData.length === 0) return Notiflix.Report.info(t(IK.bill_update_error), "", t(IK.confirm), () => {});
    const saveData = {
      billingId,
      discountTotalPrice: totals.discountPrice,
      paymentTotalPrice: totals.paymentPrice,
      saleTotalPrice: totals.salePrice,
      billingDetailSaveDTOList: updateData.map((item) => ({
        billingDetailId: item.billingDetailId,
        discountPrice: item.discountPrice,
        paymentPrice: item.paymentPrice,
        salePrice: item.salePrice,
        memo: item.memo,
      })),
    };
    saveMutation.mutate(saveData);
  };

  const tableCol = ["150px", "150px", "150px", "150px", "150px", "*", "150px", "300px"];
  const tableHead = [t(IK.pay_products), t(IK.patient_name), t(IK.pay_amount), t(IK.pay_discount), t(IK.pay_charged), t(IK.pay_notes), t(IK.delivery_date), t(IK.status)];

  /**결제 리스트 엑셀 다운로드 */
  const handleExcel = () => {
    const langInfo = localStorage.getItem("langInfo");
    getDownloadBillExcel(billingId, t, langInfo);
  };

  /**결제 요청 */
  const handlePaymentRequest = () => {
    Confirm.show(
      t(IK.request_billing_1),
      t(IK.request_billing_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        reqeustMutation.mutate(billingId);
      },
      () => {}
    );
  };

  /**결제 요청에서 결제예정 상태로 되돌리기*/
  const handleRepaymentRequest = () => {
    Confirm.show(
      t(IK.request_billing_3),
      t(IK.request_billing_4),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        reRequestMutation.mutate(billingId);
      },
      () => {}
    );
  };

  /**결제완료 내역 확인 모달 */
  const [paymentModal, setPaymentModal] = useState({
    isOpen: false,
    paymentId: "",
  });

  /**결제완료내역 모달 열기 */
  const openPaymentModal = (paymentId) =>
    setPaymentModal({
      isOpen: true,
      paymentId,
    });

  /**결제완료내역 모달 닫기 */
  const closePaymentModal = () =>
    setPaymentModal({
      isOpen: false,
      paymentId: "",
    });

  return (
    <>
      <S.CardRow>
        <S.CardCol>
          {isLoading ? (
            <BillViewSkeleton tableCol={tableCol} tableHead={tableHead} />
          ) : (
            <S.CardInner>
              <CardTitle title={`${data.addressName} ${data.billingMonth} ${t(IK.pay_request_detail)} (#${billingId})`}>
                <S2.StyledButton $excel type="submit" onClick={handleExcel} as="button">
                  <i className="ri-file-excel-2-line"></i> {t(IK.download_bill_list)}
                </S2.StyledButton>
              </CardTitle>
              <DataTable
                dataLength={data?.adminBillingDetailDTOList?.length}
                tableCol={tableCol}
                tableHead={tableHead}
                tableTr={billsInfo.map((bill) => (
                  <BillingTr
                    billingStatus={data?.billingStatus}
                    key={bill.billingDetailId}
                    billsInfo={billsInfo}
                    bill={bill}
                    setBillsInfo={setBillsInfo}
                    t={t}
                    setIsSave={setIsSave}
                    isSave={isSave}
                    openPaymentModal={openPaymentModal}
                  />
                ))}
              />
              {/**결제요청 전 */}
              {data?.billingStatus === 0 && (
                <S2.ButtonCtBox>
                  <S2.StyledButton $primary as="button" onClick={handleSave}>
                    {t(IK.pay_amount)} {t(IK.save)}
                  </S2.StyledButton>
                </S2.ButtonCtBox>
              )}

              <S4.ContentBox>
                <S5.TableType2>
                  <colgroup>
                    <col width="200px"></col>
                    <col width="*"></col>
                    <col width="200px"></col>
                    <col width="*"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">{t(IK.total_price)}</th>
                      <td>
                        {formatCurrency(totals.salePrice)}
                        {t(IK.krw)}
                      </td>
                      <th scope="row">{t(IK.total_discount)}</th>
                      <td>
                        <S4.TextDiv $textColorRed600>
                          -{formatCurrency(totals.discountPrice)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{t(IK.final_price)}</th>
                      <td>
                        <S4.TextDiv>
                          {formatCurrency(totals.paymentPrice)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                      <th scope="row">{t(IK.pay_remaining)}</th>
                      <td>
                        <S4.TextDiv $textColorBlue400 $textSize24 $textBold800>
                          {formatCurrency(totals.remainPrice)}
                          {t(IK.krw)}
                        </S4.TextDiv>
                      </td>
                    </tr>
                  </tbody>
                </S5.TableType2>
              </S4.ContentBox>

              <CardTail line>
                <S2.ButtonLtBox>
                  <S2.StyledButton as="button" onClick={() => navigate(`/billList${location.state?.herf || "?page=1"}`)}>
                    {t(IK.list)}
                  </S2.StyledButton>
                  {data.billingStatus === 0 && (
                    <S2.StyledButton as="button" $primary onClick={handlePaymentRequest}>
                      {t(IK.request_billing)}
                    </S2.StyledButton>
                  )}
                  {data.billingStatus !== 0 && (
                    <S2.StyledButton as="button" $primary onClick={handleRepaymentRequest}>
                      {t(IK.request_billing)} {t(IK.cancel)}
                    </S2.StyledButton>
                  )}
                </S2.ButtonLtBox>
              </CardTail>
            </S.CardInner>
          )}
        </S.CardCol>
      </S.CardRow>
      <PaymentDetailModal closePaymentModal={closePaymentModal} paymentModal={paymentModal} />
    </>
  );
};

export default BillView;

export const BillingTr = ({ t, bill, billsInfo, setBillsInfo, setIsSave, openPaymentModal, billingStatus }) => {
  //상태값 박스
  const [selectedStatus, setSelectedStatus] = useState(bill.billingDetailStatus || "0");
  /**제품금액 업데이트 */
  const handleSaleUpdate = (e, bill) => {
    setIsSave(true);
    const sale = e.target.value === "" ? "" : Number(e.target.value);
    const updatedData = billsInfo.map((item) => {
      if (item.billingDetailId === bill.billingDetailId) {
        if (sale < bill.discountPrice) {
          Notiflix.Report.info(t(IK.price_error_1), "", t(IK.confirm), () => {});
          return {
            ...item,
            salePrice: bill.discountPrice,
            paymentPrice: 0,
          };
        } else {
          return {
            ...item,
            salePrice: sale,
            paymentPrice: sale - bill.discountPrice,
          };
        }
      }
      return item;
    });

    setBillsInfo(updatedData);
  };
  /**헐안금액 업데이트 */
  const handleDiscountUpdate = (e, bill) => {
    setIsSave(true);
    const discount = e.target.value === "" ? "" : Number(e.target.value);
    const updatedData = billsInfo.map((item) => {
      if (item.billingDetailId === bill.billingDetailId) {
        if (discount > bill.salePrice) {
          Notiflix.Report.info(t(IK.price_error_1), "", t(IK.confirm), () => {});
          return {
            ...item,
            discountPrice: bill.salePrice,
            paymentPrice: 0,
          };
        } else {
          return {
            ...item,
            discountPrice: discount,
            paymentPrice: bill.salePrice - discount,
          };
        }
      }
      return item;
    });

    setBillsInfo(updatedData);
  };
  /**비고란 업데이트 */
  const handleMemoUpdate = (e, bill) => {
    setIsSave(true);
    const memo = e.target.value;
    const updatedData = billsInfo.map((item) => {
      if (item.billingDetailId === bill.billingDetailId) {
        return {
          ...item,
          memo,
        };
      }
      return item;
    });

    setBillsInfo(updatedData);
  };

  const statusMutation = useChangeStatus();
  /**결제상태 업데이트 */
  const handleStatuspdate = (bill) => {
    //변경된 상태가 없으면 리턴
    if (`${bill.billingDetailStatus}` === selectedStatus) return;
    //선택된 상태가 없으면 리턴
    if (!selectedStatus) return Notiflix.Report.info(t(IK.price_status_error), "", t(IK.confirm), () => {});

    const data = {
      billingDetailId: bill.billingDetailId,
    };

    //미결제 상태로 변경
    if (selectedStatus === "0") {
      data.status = "0";
      return Confirm.show(
        t(IK.price_status_0_1),
        "",
        t(IK.confirm),
        t(IK.cancel),
        () => {
          statusMutation.mutate(data);
        },
        () => {}
      );
    }

    //이월 상태로 변경
    if (selectedStatus === "99") {
      data.status = "99";
      return Confirm.show(
        t(IK.price_status_99_1),
        t(IK.price_status_99_2),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          statusMutation.mutate(data);
        },
        () => {}
      );
    }

    //직접결제 상태로 변경
    if (selectedStatus === "90") {
      data.status = "90";
      return Confirm.show(
        t(IK.price_status_90_1),
        t(IK.price_status_90_2),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          statusMutation.mutate(data);
        },
        () => {}
      );
    }

    //제외 상태로 변경
    if (selectedStatus === "80") {
      data.status = "80";
      return Confirm.show(
        t(IK.price_status_80_1),
        t(IK.price_status_80_2),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          statusMutation.mutate(data);
        },
        () => {}
      );
    }
  };

  /**결제예정 인 경우에만 금액, 할인금액 수정 가능 */
  const isBillingFixed = bill?.billingDetailStatus === 0;

  /**비고 수정, 상태 변경 가능
   * 0 : 결제예정
   * 90 : 직접 결제
   */
  const isBillingStatus = bill?.billingDetailStatus === 0 || bill?.billingDetailStatus === 90;

  return (
    <tr key={bill.billingDetailId}>
      <td>{seraphinOption[bill.packages]}</td>
      <td>
        {bill.firstName} {bill.lastName} {bill?.koreaName && `(${bill.koreaName})`}
      </td>
      <td>{!isBillingFixed ? bill.salePrice : <S3.FormControls $small value={bill.salePrice} onChange={(e) => handleSaleUpdate(e, bill)} type="number" />}</td>
      <td>{!isBillingFixed ? bill.discountPrice : <S3.FormControls $small value={bill.discountPrice} onChange={(e) => handleDiscountUpdate(e, bill)} type="number" />}</td>
      <td>
        {formatCurrency(bill.paymentPrice)}
        {t(IK.krw)}
      </td>
      <td>
        {isBillingStatus && billingStatus === 0 ? (
          <S3.FormControls as="textarea" $full $textarea $resizenone defaultValue={bill.memo} onChange={(e) => handleMemoUpdate(e, bill)} />
        ) : (
          <S4.TextDiv $textpreline>{bill.memo}</S4.TextDiv>
        )}
      </td>
      <td>{bill?.shipDate || "-"}</td>
      <td>
        {isBillingStatus && billingStatus === 0 ? (
          <S3.FormControlsFlex $colCenter>
            <S3.FormControls $select as="select" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
              <option value="0">{t(IK.upcoming_billing)}</option>
              <option value="80">{t(IK.price_status_80)}</option>
              <option value="90">{t(IK.price_status_90)}</option>
              <option value="99">{t(IK.price_status_99)}</option>
            </S3.FormControls>
            <S3.FormControlsBtn as="button" onClick={() => handleStatuspdate(bill)}>
              {t(IK.status_change)}
            </S3.FormControlsBtn>
          </S3.FormControlsFlex>
        ) : bill?.billingDetailStatus === 20 || bill?.billingDetailStatus === 15 ? (
          <S2.StyledSmallButton as="button" onClick={() => openPaymentModal(bill?.paymentId)}>
            {t(billsStatus[bill?.billingDetailStatus])} (#{bill.paymentId})
          </S2.StyledSmallButton>
        ) : (
          t(billsStatus[bill?.billingDetailStatus])
        )}
      </td>
    </tr>
  );
};
