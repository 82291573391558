import axios from "axios";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getUserPermissionCheck } from "../../apis/accountApi";
import { userAuth } from "../../atom/userAtom";
import * as S from "../../components/login/style/Login.style";
import { LogoType1 } from "../../elements/Logo/Logo";
import { ErrorMessage } from "../../elements/Message/MessageText";
import * as S2 from "../../elements/SelectionControls/style/SelectionControls.style";
import { setAllToken } from "../../utils/handleToken";
import { IK } from "../../utils/i18n/keyStore";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { accountType: "ADMIN" } });
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  /**아이디 기억하기 */
  const remberId = window.localStorage.getItem("rememberMe") || "";

  /**사용자 정보 가져오기 */
  const setUserAuth = useSetRecoilState(userAuth);

  /**공백 방지*/
  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      setValue(event.currentTarget.name, event.currentTarget.value.replace(/\s/g, ""));
    }
  };

  /**공백입력시 제거 */
  const handleTrim = (event) => {
    const trimValue = event.target.value.replace(/\s/g, "");
    setValue(event.currentTarget.name, trimValue);
  };

  /**로그인 요청 */
  const onLoginSubmit = async (data) => {
    await axios
      .post("/api/auth/login", data)
      .then((response) => {
        if (response.status === 200) {
          setAllToken(response.data.data.accessToken);
          getUserPermissionCheck().then((res) => {
            setUserAuth(res?.data?.data);
          });
          localStorage.setItem("user", response.data.data?.roleInfo?.ADMIN.name);
          if (data.rememberMe) {
            window.localStorage.setItem("rememberMe", data.username);
          } else {
            localStorage.removeItem("rememberMe");
          }
          navigate("/studyList");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) Notiflix.Report.failure(t(IK.id_admin), t(IK.id_auth), t(IK.confirm));
        return Notiflix.Report.failure(t(IK.authority_message), t(IK.recheck_message), t(IK.confirm));
      });
  };

  return (
    <S.LoginContainer>
      <S.LoginBox onSubmit={handleSubmit(onLoginSubmit)}>
        <S.LoginLogo>
          <LogoType1 />
        </S.LoginLogo>

        <S.LoginFormBox>
          <S.LoginForm>
            <input type="text" {...register("username", { required: t(IK.id_required) })} defaultValue={remberId} onKeyDown={handleKeyDown} onChange={(event) => handleTrim(event)} />
            <S.LoginFormFocus />
          </S.LoginForm>
          <ErrorMessage errors={errors} name="username" />
        </S.LoginFormBox>

        <S.LoginFormBox>
          <S.LoginForm>
            <input type="password" {...register("password", { required: t(IK.password_required) })} onKeyDown={handleKeyDown} onChange={(event) => handleTrim(event)} />
            <S.LoginFormFocus />
          </S.LoginForm>
          <ErrorMessage errors={errors} name="password" />
        </S.LoginFormBox>

        <S.LoginContact>
          <S2.SelectionItem $Small>
            <S2.SelectionItemLabel>
              <S2.SelectionItemInput type="checkbox" $checkbox {...register("rememberMe")} defaultChecked={remberId} />
              <S2.SelectionItemSpan>{t(IK.rememberme)}</S2.SelectionItemSpan>
            </S2.SelectionItemLabel>
          </S2.SelectionItem>
        </S.LoginContact>

        <S.LoginButton>{t(IK.login)}</S.LoginButton>
        <S.LoginCopy>{t(IK.tns_copyright)}</S.LoginCopy>
      </S.LoginBox>
    </S.LoginContainer>
  );
};

export default LoginForm;
