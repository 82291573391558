import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { usePostEducationVideo, usePutEducationVideo } from "../../apis/supportApi";
import * as S from "../../components/Modal/style/Modal.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { ErrorMessage, TextErrorMessage } from "../../elements/Message/MessageText";
import { enterKeyDown } from "../../utils/enterKeydown";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";
import CountryBtn from "../country/CountryBtn";
import SelectedCountryList from "../country/SelectedCountryList";

const VideoAddModal = ({ openModal, handleClose, isAllCountry }) => {
  const { t } = useTranslation(["translation"]);

  /**유효성 검사 */
  const formSchema = Yup.object({
    countryCodes: Yup.array().required(t(IK.country_required)).min(1, t(IK.country_required)),
    supportVideo: Yup.object().shape({
      video1: Yup.string().required(t(IK.pop_link_required)),
      video2: Yup.string().required(t(IK.pop_link_required)),
      video3: Yup.string().required(t(IK.pop_link_required)),
      video4: Yup.string().required(t(IK.pop_link_required)),
    }),
  });

  /**수정 폼 관리 */
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const boardWatch = watch();

  /**추가하기 */
  const postVideo = usePostEducationVideo();
  /**수정하기 */
  const putVideo = usePutEducationVideo(openModal.videoData?.supportLinkId);

  const onSubmit = (value) => {
    const mutation = openModal.videoData ? putVideo : postVideo;

    mutation.mutate(value, {
      onSuccess: () => {
        handleClose();
        reset();
      },
    });
  };

  useEffect(() => {
    if (openModal.videoData) {
      const { countryCodes, video } = openModal.videoData;
      reset({ countryCodes, supportVideo: video });
    } else {
      reset({
        countryCodes: [],
        supportVideo: {
          video1: "",
          video2: "",
          video3: "",
          video4: "",
        },
      });
    }
  }, [openModal.videoData, reset]);

  return (
    <ModalLayout open={openModal.isOpen} header={`${t(IK.support_link)} ${openModal.videoData ? t(IK.modify) : t(IK.add)}`} handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={enterKeyDown}>
        <S2.FormControlsColumn>
          {/* 국가 선택 */}
          {isAllCountry && boardWatch?.countryCodes?.[0] !== "00" && (
            <>
              <FormControlsBox required title={t(IK.country)}>
                <CountryBtn setValue={setValue} clearErrors={clearErrors} watch={boardWatch?.countryCodes} />
                <ErrorMessage errors={errors} name="countryCodes" />
              </FormControlsBox>
              {boardWatch?.countryCodes?.length > 0 && (
                <FormControlsBox required title={`${t(IK.country)} ${t(IK.list)}`}>
                  <SelectedCountryList watch={boardWatch.countryCodes} setValue={setValue} t={t} />
                </FormControlsBox>
              )}
            </>
          )}
          <FormControlsBox required title={`${t(IK.support_link)} 1`}>
            <S2.FormControls placeholder="STEP1" {...register("supportVideo[video1]")} />
            <TextErrorMessage errors={errors?.supportVideo?.video1} message={t(IK.pop_link_required)} />
          </FormControlsBox>
          <FormControlsBox required title={`${t(IK.support_link)} 2`}>
            <S2.FormControls placeholder="STEP2" {...register("supportVideo[video2]")} />
            <TextErrorMessage errors={errors?.supportVideo?.video2} message={t(IK.pop_link_required)} />
          </FormControlsBox>
          <FormControlsBox required title={`${t(IK.support_link)} 3`}>
            <S2.FormControls placeholder="STEP3" {...register("supportVideo[video3]")} />
            <TextErrorMessage errors={errors?.supportVideo?.video3} message={t(IK.pop_link_required)} />
          </FormControlsBox>
          <FormControlsBox required title={`${t(IK.support_link)} 4`}>
            <S2.FormControls placeholder="STEP4" {...register("supportVideo[video4]")} />
            <TextErrorMessage errors={errors?.supportVideo?.video4} message={t(IK.pop_link_required)} />
          </FormControlsBox>
        </S2.FormControlsColumn>
        <S.ModalBoxBtn>
          <S3.ButtonFlex>
            <S3.StyledButton as="button" type="button" onClick={handleClose}>
              {t(IK.cancel)}
            </S3.StyledButton>
            <S3.StyledButton as="button" type="submit" $primary>
              {openModal.videoData ? t(IK.modify) : t(IK.add)}
            </S3.StyledButton>
          </S3.ButtonFlex>
        </S.ModalBoxBtn>
      </form>
    </ModalLayout>
  );
};

export default VideoAddModal;
